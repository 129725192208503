import Vue from "vue"
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import Documents from '@/components/widgets/documents'
import Customers from '@/components/widgets/customers'
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Loading from '@/components/widgets/Loading.vue'
import moment from "moment";

const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    Documents,
    Customers,
    Loading
  },
  mixins: [validationMixin],
  validations: {
    formData: {
      paid_at: { required },
      amount: { required },
      ref_id: { required: requiredIf(function() {
        return this.formData.type === 'quotation'
      }) },
      customer_id: { required: requiredIf(function() {
        return this.formData.type === 'customer'
      }) },
    },
  },
  data() {
    return {
      max: null,
      loading: false,
      docOpened: false,
      customerOpened: false,
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,

      customerFields: [
        { 
          key: 'code', 
          label: 'รหัส', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2'
        },
        { 
          key: 'name', 
          label: 'ชื่อ - นามสกุล', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-left text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'address', 
          label: 'ที่อยู่', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-left text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'tax_id', 
          label: 'เลขประจำตัวผู้เสียภาษี', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-left text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'contact', 
          label: 'ติดต่อ', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-left text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'text-center font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-center td-top text-xs-1/2',
        }
      ],
      customer: [],
      // fields: [
      //   { 
      //     key: 'bill_code', 
      //     label: 'รหัส',
      //     thClass: 'font-weight-bold text-xs-1/2',
      //     tdClass: 'td-top text-xs-1/2',
      //     sortable: true 
      //   },
      //   { 
      //     key: 'bill_name', 
      //     label: 'ชื่อ',
      //     thClass: 'font-weight-bold text-xs-1/2',
      //     tdClass: 'text-gray-400 td-top text-xs-1/2',
      //     sortable: true 
      //   },
      //   { 
      //     key: 'customer', 
      //     label: 'ลูกค้า',
      //     thClass: 'font-weight-bold text-xs-1/2',
      //     tdClass: 'text-gray-400 td-top text-xs-1/2',
      //     sortable: true 
      //   },
      //   { 
      //     key: 'bill_total_qty', 
      //     label: 'รวมจำนวน(หน่วย)',
      //     thClass: 'font-weight-bold text-xs-1/2',
      //     tdClass: 'text-gray-400 td-top text-xs-1/2',
      //     class: 'text-right',
      //     sortable: true 
      //   },
      //   { 
      //     key: 'bill_total_length', 
      //     label: 'รวมยาว(m)',
      //     thClass: 'font-weight-bold text-xs-1/2',
      //     tdClass: 'text-gray-400 td-top text-xs-1/2', 
      //     class: 'text-right',
      //     sortable: true 
      //   },
      //   { 
      //     key: 'bill_length_use', 
      //     label: 'รวมยาวใช้จริง(m)',
      //     thClass: 'font-weight-bold text-xs-1/2',
      //     tdClass: 'text-gray-400 td-top text-xs-1/2',
      //     class: 'text-right',
      //     sortable: true 
      //   },
      //   { 
      //     key: 'bill_grand_total', 
      //     label: 'ยอดรวมสุทธิ',
      //     thClass: 'font-weight-bold text-xs-1/2',
      //     tdClass: 'text-gray-400 td-top text-xs-1/2',
      //     class: 'text-right',
      //     sortable: true
      //   },
      //   { 
      //     key: 'bill_manipulator', 
      //     label: 'ผู้ทำรายการ', 
      //     thClass: 'font-weight-bold text-xs-1/2',
      //     tdClass: 'text-gray-400 td-top text-xs-1/2',
      //     sortable: true 
      //   },
      //   { 
      //     key: 'status', 
      //     label: 'สถานะ', 
      //     thClass: 'font-weight-bold text-xs-1/2',
      //     tdClass: 'text-gray-400 td-top text-xs-1/2',
      //     sortable: true 
      //   },
      //   { 
      //     key: 'action', 
      //     label: '#', 
      //     thClass: 'font-weight-bold text-xs-1/2',
      //     tdClass: 'text-gray-400 td-top text-xs-1/2',
      //   }
      // ],
      fields: [
        {
          key: "quotation_no",
          label: "เลขที่ใบเสนอราคา",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "td-top text-xs-1/2",
          sortable: true,
        },
        {
          key: "quotation_date",
          label: "ลงวันที่",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          sortable: true,
        },
        {
          key: "customer_detail.name",
          label: "ชื่อลูกค้า",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          sortable: true,
        },
        {
          key: "customer_detail.company_name",
          label: "บริษัท",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          sortable: true,
        },
        {
          key: "grandtotal",
          label: "มูลค่า",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          sortable: true,
        },
        {
          key: "creator_detail.name",
          label: "ผู้จัดทำ",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          sortable: true,
        },
        {
          key: "status",
          label: "สถานะ",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          sortable: true,
        },
        {
          key: "action",
          label: "#",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          class: "text-right",
        },
      ],
      items: [],
      formData: {
        document_no: '',
        paid_at: null,
        amount: null,
        customer_id: null,
        receipt_id: null,
        ref_id: null,
        type: 'quotation',
      }
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  mounted() {
    this.onInitData()
  },
  watch:{
    "formData.amount"(new_val, old_val) {
      let newValF = parseFloat(new_val);
      let maxF = parseFloat(this.max);

      if (newValF > maxF) {
        this.$nextTick(() => {
          this.formData.amount = maxF;
        });
      }
    },
  },
  methods: {
    onClearData() {
      this.items = []
      this.customer = []
      this.formData.document_no = ''
      this.formData.paid_at = null
      this.formData.amount = 0
      this.formData.customer_id = ''
      this.formData.receipt_id = null
      this.formData.ref_id = null
      this.formData.type = 'customer'
    },
    resetData(){
      this.items = []
      this.customer = []
    },
    balckToDepositList() {
      this.$router.push("/deposit")
    },
    onRemoveDocument(id) {
      const index = _.findIndex(this.items, function(o) { return o.uuid == id })
      this.items.splice(index, 1)
    },
    onInitDocuments(items) {
      this.docOpened = false
      if (items.status) {
        this.items = []
        this.items.push(items.data)
        this.formData.ref_id = items.data.id
        this.formData.amount = null
        if(items.data.deposit_amount != null || items.data.deposit_percent != null){
          this.formData.amount = items.data.deposit_amount ? items.data.deposit_amount : items.data.deposit_percent * items.data.grandtotal / 100
        }
        this.max = items.data.grandtotal
        // if(this.items.length === 0) {
        //   this.items.push(items.data)
        // } else {
        //   for (let index = 0; index < items.length; index++) {
        //     const element = items[index]
        //     const document = _.find(this.items, { uuid: element.uuid })
        //     if (!document) {
        //       this.items.push(items.data)
        //     }
        //   }
        // }
      }
    },
    onselectDocument() {
      this.docOpened = !this.docOpened
    },
    onRemoveCustomer(id) {
      const index = _.findIndex(this.customer, function(o) { return o.uuid == id })
      this.customer.splice(index, 1)
    },
    onInitCustomer(item) {
      this.customerOpened = false
      this.customer = []
      if (item.data && item) {
        this.customer.push(item.data)
        this.formData.customer_id = this.customer[0].id
      }
    },
    onselectCustomer() {
      this.customerOpened = !this.customerOpened
    },
    async onSubmitForm() {
      this.loading = true
      let result = null

      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        this.loading = false
        return
      }
      this.formData.amount = parseFloat(this.formData.amount)
      this.formData.paid_at = moment(this.formData.paid_at).format("YYYY-MM-DD")
      
      if (this.$route.params.id) {
        result = await this.$store.dispatch('Deposit/update', { form: JSON.stringify(this.formData), Id: this.$route.params?.id }, { root: true })
      } else {
        result = await this.$store.dispatch('Deposit/create', JSON.stringify(this.formData), { root: true })
      }
      
      setTimeout(() => {
        this.loading = false
        if (result?.status === 201 || result?.status === 200) {
                   
          this.$swal({
            width: "29rem",
            padding: '1.825em',
            html: '<div class="d-flex flex-row align-items-center">' +
              '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
              '<div class="d-flex flex-column">'+
              '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
              '<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>'+
              '</div>'+
            '</div>',
              allowOutsideClick: false,
              focusConfirm: false,
              customClass: {
                popup: 'swal2-custom-rounded',
                closeButton: 'text-3xl swal2-custom-close',
                actions: 'justify-content-end',
                contant: 'd-flex flex-row justify-content-around px-2',
                title: 'd-none',
                confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
              },
              cancelButtonColor: '#dc4a38',
              cancelButtonText: 'ยกเลิก',
              confirmButtonText: "ตกลง",
              showCancelButton: false,
              showCloseButton: true,
          }).then(async confirm => {
            if(confirm.value) {
              this.onClearData()
              this.$router.push("/deposit");
            }
          })
        } else {
          this.onExceptionHandler('ไม่พบข้อมูล');
        }
      }, 1000);
    },
    onEventClose() {
      this.$emit('componentInfo', { 
        status: true,
        component: 'deposit-list'
      })
    },
    onEventQtyChange(e, id) {
      let value = parseInt(e)
      let result = _.find(this.items, { uuid: id })
      if (result) {
        result.deposit = value
      }
    },    
    onInitData() {
      if (this.$route.params?.id) {
        try {
          this.loading = true
          setTimeout(async () => {
            const param = {
              _id: this.$route.params?.id
            }
            const result = await this.$store.dispatch('Deposit/get', param, { root: true })
            if (result.status === 200) {
              this.loading = false
              // this.onFormBuild(result.data)
              this.formData.document_no = result.data.document_no
              this.formData.paid_at = new Date(result.data.paid_at)
              // this.formData.customer_id = result.data.customer_id
              // this.formData.receipt_id = result.data.receipt_id
              this.formData.ref_id = result.data.ref_id

              // const customers = result.data.customers.length > 0 ? result.data.customers[0]: []
              // this.onInitCustomer({ data: customers })

              const documents = result.data.bills.length > 0 ? result.data.bills[0]: []
              this.onInitDocuments({ status: true, data: documents })
              this.formData.amount = result.data.amount

            }
          }, 1000);
        } catch (error) {
          this.loading = false
          this.onExceptionHandler(error.response.data.message);
        }
      }
    }
  }
}